define(["require", "exports", "./importMapOverride", "./requirejs-config-extension"], function (require, exports, importMapOverride_1, requirejs_config_extension_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    const librariesRequireJsConfig = __librariesRequireJsConfig__;
    const scriptUrl = document.currentScript.src;
    const absUrl = (pathname) => new URL(pathname, scriptUrl).href;
    const importMapOverride = new importMapOverride_1.ImportMapOverride();
    const config = importMapOverride.overrideRequireJsConfig({
        paths: {
            ...Object.fromEntries(Object.entries(librariesRequireJsConfig.paths).map(([key, value]) => [key, absUrl(value)])),
            // vendor
            'jquery.touchpunch': absUrl('jquery.ui.touch-punch'),
            history: absUrl('history'),
            // PSF portal
            psfapi: absUrl(`../../${window.psfResourceHash || ''}/psf/psf.portal`),
            psfapiScreen: absUrl(`../../${window.psfResourceHash || ''}/psf/psf.screen`),
            // pdp scripts
            error: absUrl('../public/scripts/error'),
            initialization: absUrl('initialization'),
            fipmenubehaviour: absUrl('fipmenubehaviour'),
            logout: absUrl('../public/scripts/logout'),
            login: absUrl('../public/scripts/login'),
            loginLogic: absUrl('../public/scripts/loginLogic'),
            loginScript: absUrl('../public/scripts/loginScript'),
            logoutScript: absUrl('../public/scripts/logoutScript'),
            customLoginScript: absUrl('../public/scripts/customLoginScript'),
            adminLoginScript: absUrl('adminLoginScript'),
            fipApplicationInfo: absUrl('../public/scripts/fipApplicationInfo'),
            displayError: absUrl('../public/scripts/displayError'),
            loadingText: absUrl('../public/scripts/loadingText'),
            // public scripts
            demoLoginScript: absUrl('demoLoginScript'),
            ...requirejs_config_extension_1.configExtension.paths
        },
        map: {
            '*': {
                jQuery: 'jquery',
                React: 'react',
                ReactDOM: 'react-dom',
                'react-dom/client': 'react-dom',
                ReactRedux: 'react-redux',
                ReactMeasure: 'react-measure',
                AutosizeInput: 'react-input-autosize',
                Squire: 'squire-rte',
                Moment: 'moment',
                ReactCustomScrollbars: 'react-custom-scrollbars',
                Dropzone: 'react-dropzone',
                'moment/moment': 'moment',
                ...librariesRequireJsConfig.map
            }
        },
        shim: {
            'jquery.mousewheel': {
                deps: ['jquery']
            },
            validate: {
                deps: ['jquery']
            },
            fipApplicationInfo: {
                deps: ['jquery']
            },
            widget: {
                deps: ['jquery']
            },
            mouse: {
                deps: ['jquery', 'widget']
            },
            sortable: {
                deps: ['jquery', 'core', 'mouse', 'widget']
            },
            selectize: {
                deps: ['jquery']
            },
            psfapi: {
                deps: ['jquery', 'handlebars', 'jquery.cookie', 'jquery-validation']
            },
            fipmenubehaviour: {
                deps: ['jquery', 'psfapi']
            },
            initialization: {
                deps: [
                    'jquery',
                    'sortable',
                    'selectize',
                    'bootstrap',
                    'jquery.mousewheel',
                    'fipmenubehaviour',
                    'psfapi',
                    'fipApplicationInfo',
                    '@coconet/modal-dialog',
                    '@coconet/icon'
                ]
            },
            'jquery.touchpunch': {
                deps: ['jquery', 'core', 'mouse', 'widget']
            },
            registerScript: {
                deps: ['jquery', 'psfapi', 'jquery.cookie', 'handlebars']
            },
            logoutScript: {
                deps: ['jquery', 'psfapi', 'jquery.cookie', 'fipApplicationInfo']
            },
            loginScript: {
                deps: ['jquery', 'psfapi', 'jquery.cookie', 'handlebars', 'loginLogic', 'fipApplicationInfo']
            },
            displayError: {
                deps: ['jquery', 'psfapi', 'jquery.cookie', 'fipApplicationInfo']
            },
            demoLoginScript: {
                deps: ['jquery', 'psfapi', 'jquery.cookie']
            },
            customLoginScript: {
                deps: ['jquery', 'psfapi', 'jquery.cookie', 'loginScript']
            },
            ...requirejs_config_extension_1.configExtension.shim
        },
        bundles: librariesRequireJsConfig.bundles,
        waitSeconds: 0
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (!window.requirejs) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        window.requirejs = config;
    }
    else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        window.requirejs.config(config);
    }
});
