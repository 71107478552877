define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.configExtension = void 0;
    const scriptUrl = document.currentScript.src;
    const absUrl = (pathname) => new URL(pathname, scriptUrl).href;
    exports.configExtension = {
        shim: {
            react: {
                exports: 'React'
            },
            'react-dom': {
                deps: ['react'],
                exports: 'ReactDOM'
            },
            'user-form': {
                deps: ['psfapi', 'react', 'react-dom']
            }
        },
        paths: {
            'user-form': absUrl('../scripts/UserFormApp'),
            'ident-finished': absUrl('../scripts/IdentFinishedApp')
        }
    };
});
