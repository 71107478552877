define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.ImportMapOverride = void 0;
    class ImportMapOverride {
        constructor() {
            this.overrides = new Map(Object.entries(localStorage)
                .filter(([key]) => key.startsWith('import-map-override::'))
                .map(([key, value]) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const libraryName = key.split('::')[1];
                return [libraryName, value];
            }));
            if (this.overrides.size > 0) {
                console.info('RequireJS overrides enabled', this.overrides);
            }
        }
        overrideRequireJsConfig(config) {
            config.paths = Object.fromEntries(Object.entries(config.paths).map(([libraryName, value]) => {
                if (this.overrides.has(libraryName)) {
                    const override = this.overrides.get(libraryName);
                    return [libraryName, Array.isArray(value) ? [override, ...value] : [override, value]];
                }
                return [libraryName, value];
            }));
            [...this.overrides.entries()].forEach(([key, value]) => {
                if (!config.paths[key]) {
                    config.paths[key] = value;
                }
            });
            return config;
        }
    }
    exports.ImportMapOverride = ImportMapOverride;
});
